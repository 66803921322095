<template>
   <section>
   
    <div class="container" :class="{'d-none':!gecerliGuid}">
      <div class="user signinBx">
        <div class="imgBx">

        <img src="/src/assets/monoryumLogoTam.png" alt="Monoryum E-Fatura" />
        </div>
        <div class="formBx" >

          <form action="" autocomplete="off" >
          <span>Güvenlik Kodunuzu ve  Yeni Şifrenizi girerek işlemi tamamlayabilirsiniz.</span>
            <input type="text" v-model="onayKodu" @blur="$v.onayKodu.$touch()" :class="{ 'invalidData': $v.onayKodu.$error }" placeholder="Güvenlik Kodu" />
             
            <input type="text" v-model="sifre" @blur="$v.sifre.$touch()" :class="{ 'invalidData':$v.sifre.$error }" placeholder="Yeni Şifre" />
            <span v-if="sifre.length>0 && sifre.length  < 6"   class="form-text text-danger">Şifreniz min. {{ $v.sifre.$params.minLength.min }} karakterli olmalıdır.</span>
            <button type="button" :disabled="$v.$invalid" @click="yeniSifreOlustur" class="btn btn-block w-100 ">Yeni Şifremi Oluştur</button>
             
            
             <p class="lostPassDiv">
                Şifremi Biliyorum Giriş Yapmak istiyorum...
              <a href="#" @click="$router.push('/')" >MONORYUM'A GİRİŞ</a>
            </p> 
          </form>
        </div>
      </div>
     
    </div>
</section>


</template>

<script>
 import  {required,email, minLength} from  'vuelidate/lib/validators'
import axios from 'axios'

export default ({
     data(){
         return {
             gelenGuidi: this.$route.params.guidi,
             onayKodu:'',
             sifre:'' ,
             gecerliGuid:false 
         }
     },
     validations:{
         onayKodu:{
             required
         },
         sifre:{
             required,
             minLength:minLength(6)
         }
     },
    methods:{
        sifreGuidKontrol(){
            this.$store.state.loadAnimAc = true
             
            var qs =require("qs");
            var data =  qs.stringify({
                'guidi': this.gelenGuidi
            });
            
            var config = {
                method: 'post',
                url: '/api/usersRoute/sifreAlGuidKontrol',
                headers: { 
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                data : data
              };


            axios(config).then(res => {
               
                this.gecerliGuid =res.data.sonuc;
                 
              
                this.$store.state.loadAnimAc = false
                if(!res.data.sonuc){
                         
                  
                            this.$store.dispatch("modalAlertAc",{ 
                                yazi: res.data.mesaj,
                                okButton:"Tamam",
                                canButton:"İptal",
                                distanKapat  : true
                            })
                            

                            this.$router.push('/')
                        
                }
              
                

            }).catch(error=>{
              
              this.$store.state.loadAnimAc = false
                    
              
              this.$store.dispatch("modalAlertAc", { 
                  yazi:"Hata..GUID Geçersiz.",
                  okButton:"Tamam",
                  canButton:"İptal",
                  distanKapat  : true
              });
                     this.$router.push('/')   
                   
                   
            })

        
        },
       
        yeniSifreOlustur(){
            this.$store.state.loadAnimAc = true
             
            var qs =require("qs");
            var data =  qs.stringify({
                'guidi': this.gelenGuidi,
                'onaykodu':this.onayKodu,
                'yenisifre':this.sifre
            });
            
            var config = {
                method: 'post',
                url: '/api/usersRoute/yeniSifreOlustur',
                headers: { 
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                data : data
              };


            axios(config).then(res => {
               
                this.gecerliGuid =res.data.sonuc;
                   console.log(res.data)
              
                this.$store.state.loadAnimAc = false
                   this.$store.dispatch("modalAlertAc",{ 
                                yazi: res.data.mesaj,
                                okButton:"Tamam",
                                canButton:"İptal",
                                distanKapat  : true
                            })
                            
this.$router.push('/')
            
                

            }).catch(error=>{
              
              this.$store.state.loadAnimAc = false
                    
              
              this.$store.dispatch("modalAlertAc", { 
                  yazi:"Hata..GUID Geçersiz.",
                  okButton:"Tamam",
                  canButton:"İptal",
                  distanKapat  : true
              });
                        
                   this.$router.push('/')
                   
            })
        }
    },
     created(){
         this.sifreGuidKontrol()
         
     }
})
</script>

<style scoped>


.invalidData{

  border:1px solid red !important;
   
}

section {
  position: relative;
  min-height: 100vh;
  background-color: #670ca3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
 
}

section .container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
   border-radius: 20px;;
}

section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: white;
  transition: 0.5s;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
}

section .container .user .imgBx img {
  
   
  width: 50%;
  height: auto !important;
   
   
}

section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

section .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

section .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}


section .container .user .formBx form button[type='button'] {
  
  background: #670ca3;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}
section .container .user .formBx form button[type='submit'] :disabled {
  
  background: lightgray;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}
section .container .user .formBx form .signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}
section .container .user .formBx form .lostPassDiv {
  position: absolute;
  bottom: 0;
  right: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}
section .container .user .formBx form .lostPassDiv a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

section .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}
 
section .container .signupBx {
  pointer-events: none;
}

section .container.active .signupBx {
  pointer-events: initial;
}

section .container .signupBx .formBx {
  left: 100%;
}

section .container.active .signupBx .formBx {
  left: 0;
}

section .container .signupBx .imgBx {
  left: -100%;
}

section .container.active .signupBx .imgBx {
  left: 0%;
}

section .container .signinBx .formBx {
  left: 0%;
}

section .container.active .signinBx .formBx {
  left: 100%;
}

section .container .signinBx .imgBx {
  left: 0%;
}

section .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  section .container {
    max-width: 400px;
  }

  section .container .imgBx {
    display: none;
  }

  section .container .user .formBx {
    width: 100%;
  }
}

</style>
